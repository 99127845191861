export const gridDefaultColDef = {
  resizable: true,
  sortable: true,
};

export function gridColumns(ProfileLink) {
  return [
    {
      headerName: 'Full Name',
      sort: 'asc',
      sortable: false,
      flex: 1,
      valueGetter: (params) => params.data,
      cellRenderer: ProfileLink,
      comparator: (valueA, valueB) => {
        if (valueA.surname === valueB.surname) return 0;
        return (valueA.surname > valueB.surname) ? 1 : -1;
      },
    },
    {
      headerName: 'Role(s)',
      flex: 1,
      sortable: false,
      autoHeight: true,
      cellRenderer: (params) => {
        const { positions } = params.data;

        return positions
          .map((p) => `<div>${p.ancestors.join(', ')}</div>`)
          .join('');
      },
    },
  ];
}
